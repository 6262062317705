import React, { useContext, useEffect, useRef, useState } from "react";
import NotifyScreen from "./Component/notify";
import { useParams, useHistory } from "react-router-dom";
import { AgoraContext } from "../../Context/AgoraContext";
import AgoraRTC from "agora-rtc-sdk-ng";
import { socket } from "../utils/Client";
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  facingMode: FACING_MODE_USER,
};
let rtc = {
  client: null,
  localAudioTrack: null,
  localVideoTrack: null,
};
let camStream = true;
const NotifyView = () => {
  let { audit_id } = useParams();
  const history = useHistory();
  const [question, setquestionlist] = useState();
  const [online, setonline] = useState(false);
  const [qty, setqty] = useState();
  var [intervalId, setintervalId] = useState("");
  const [auditStart, setAuditStart] = useState(false);
  const [imgurl, setimgurl] = useState("");
  const [requestData, setrequestData] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [token, settoken, channel, setchannel] = useContext(AgoraContext);
  const [rating, setRating] = useState(0);
  const [remark, setremark] = useState("");
  const [front, setFront] = useState(true);
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
  const [camToggle, setcamToggle] = useState(true);
  const camToggleRef = useRef(true);
  const [micToggle, setmicToggle] = useState(true);
  const [bmplay, setbmplay] = useState(1);
  const [rmmplay, setrmmplay] = useState(1);
  const [actionablescore, setactionablescore] = useState(0);
  const options = {
    // Pass your app ID here.
    appId: "b13f7540466747e6a102327255673a59",
    // Set the channel name.
    channel: channel,
    // Pass a token if your project enables the App Certificate.
    //token:null,
    token: token,
  };
  async function startBasicCall() {
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    const uid = await rtc.client.join(
      options.appId,
      options.channel,
      options.token,
      null
    );
    // Create an audio track from the audio sampled by a microphone.
    rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    // Create a video track from the video captured by a camera.
    rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    // Publish the local audio and video tracks to the channel.
    var d = await rtc.client.publish([
      rtc.localAudioTrack,
      rtc.localVideoTrack,
    ]);
    const remoteVideoTrack = rtc.localVideoTrack;
    const playerContainer = document.getElementById("player");
    // Specify the ID of the DIV container. You can use the `uid` of the remote user.
    playerContainer.style.width = "100%";
    playerContainer.style.height = "480px";
    remoteVideoTrack.play(playerContainer);
    rtc.client.on("user-published", async (user, mediaType) => {
      // Subscribe to a remote user.
      await rtc.client.subscribe(user, mediaType);
      setonline(true);
      // If the subscribed track is video.
      if (mediaType === "video") {
        // Get `RemoteVideoTrack` in the `user` object.
        const remoteVideoTrack = user.videoTrack;
        // Dynamically create a container in the form of a DIV element for playing the remote video track.
        const playerContainer = document.createElement("div");
        // Specify the ID of the DIV container. You can use the `uid` of the remote user.
        playerContainer.id = user.uid.toString();
        playerContainer.style.width = "0px";
        playerContainer.style.height = "0px";
        document.body.append(playerContainer);
        // Play the remote video track.
        // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
        remoteVideoTrack.play(playerContainer);

        // Or just pass the ID of the DIV container.
        // remoteVideoTrack.play(playerContainer.id);
      }

      // If the subscribed track is audio.
      if (mediaType === "audio") {
        // Get `RemoteAudioTrack` in the `user` object.
        const remoteAudioTrack = user.audioTrack;
        // Play the audio track. No need to pass any DOM element.
        remoteAudioTrack.play();
      }
    });
  }
  async function leaveCall() {
    // Destroy the local audio and video tracks.
    if (rtc.localAudioTrack != null || rtc.localVideoTrack != null) {
      rtc.localAudioTrack.close();
      rtc.localVideoTrack.close();
      // Leave the channel.
      await rtc.client.leave();
    }
  }
  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);
  useEffect(() => {
    handleUploadimage();
  }, []);
  useEffect(() => {
    startBasicCall();
    return () => {
      leaveCall();
    };
  }, []);

  useEffect(() => {
    socket.on("checker", (data) => {
      if (data.socketEvent == `checker${audit_id}`) {
        setImagePreview(null);
      }
    });
    socket.on("bm_online", (data) => {
      if (data.socketEvent == `pauseOnlineAudit${audit_id}`) {
        setbmplay(data.data.bm_online);
      }
    });
    socket.on("rating_sent", (data) => {
      if (data.audit_id == audit_id) {
        setRating(data.val);
      }
    });
    socket.on("remark_sent", (data) => {
      if (data.audit_id == audit_id) {
        setremark(data.val);
      }
    });
    socket.on("qtyview_sent", (data) => {
      if (data.audit_id == audit_id) {
        setqty(data.qty);
      }
    });
    socket.on("startcalling", (data) => {
      if (data.socketEvent == `getQuestionList${audit_id}`) {
        if (data.status == 200) {
          setquestionlist(data.data);
          setimgurl(data.base_url);
          setAuditStart(true);
          setRating(0);
          setremark("");
          setqty("");
        } else {
          window.location.assign(`/actionable/${audit_id}`);
        }
      }
    });
    socket.on("completeQuestionAudit", (data) => {
      if (data.audit_id == audit_id) {
        window.location.assign(`/actionable/${audit_id}`);
      }
    });
    socket.on("getpreviousquestion", (data) => {
      if (data.socketEvent == `previousquestion${audit_id}`) {
        setquestionlist(data.data);
        setimgurl(data.base_url);
        setAuditStart(true);
        setRating(data.answer.score_range);
        setremark(data.answer.remark);
      }
    });
    socket.on("captureImageAccept", (data, callback) => {
      if (data.socketEvent == `captureImageAccept${audit_id}`) {
        handleUploadimage();
      }
    });
    socket.on("deleteCaptureReponse", (data, callback) => {
      if (data.status == 200) {
        if (data.socketEvent == `deleteCaptureImg${audit_id}`) {
          setimgurl(data.base_url);
          setImagePreview(data?.data);
        }
      }
    });
  }, []);

  const handleUploadimage = async () => {
    const params = { audit_id: audit_id };
    socket.emit("getImagedata", params, (data) => {
      if (data.socketEvent == `getImagedata${audit_id}`) {
        uploadIMGDataSocket(data);
      }
    });
  };
  const uploadIMGDataSocket = (data) => {
    if (data.status === 200) {
      if (data?.data[0]?.status === 0) {
        setrequestData(data.data[0]);
        captuepic(data.data[0].id, data.data[0].question_id);
      }
    }
  };
  const captuepic = (id, question_id) => {
    const player = document.getElementById("player1");
    setTimeout(() => {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: camToggleRef.current === true ? "user" : "environment",
          },
        })
        .then((stream) => {
          player.srcObject = stream;
          const imageWidth = document.getElementById("player1").offsetWidth;
          const imageHeight = document.getElementById("player1").offsetHeight;
          const outputCanvas = document.getElementById("output");
          const context = outputCanvas.getContext("2d");
          outputCanvas.width = imageWidth;
          outputCanvas.height = imageHeight;
          setTimeout(() => {
            context.drawImage(
              document.getElementById("player1"),
              0,
              0,
              imageWidth,
              imageHeight
            );
            outputCanvas.toBlob((blob) => {
              //   var url = URL.createObjectURL(blob);
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                var base64data = reader.result;
                handleCaptureImage(base64data, id, question_id);
              };
            });
          }, 2000);
          setTimeout(() => {
            stopnewCapture(stream);
          }, 3000);
        })
        .catch((error) => {
          alert(error);
        });
    }, 600);
  };

  const handleCaptureImage = async (blob, id, question_id) => {
    const params = {
      audit_id: audit_id,
      question_id: question_id,
      id: id,
      image_data: blob,
    };
    socket.emit("updateCaptureimage", params, (data) => {
      if (data.status == 200) {
        if (data.socketEvent == `updateCaptureimage${audit_id}${question_id}`) {
          setimgurl(data.base_url);
          setImagePreview(data?.image_data);
          setrequestData(data.data);
        }
      }
    });
  };
  const toggleCam = (state) => {
    AgoraRTC.getDevices().then((devices) => {
      const videoDevices = devices.filter(function (device) {
        return device.kind === "videoinput";
      });
      if (videoDevices.length > 1) {
        if (state) {
          rtc.localVideoTrack
            .setDevice(videoDevices[0].deviceId)
            .then(() => {})
            .catch((e) => {
              alert(e);
            });
        } else {
          rtc.localVideoTrack
            .setDevice(videoDevices[videoDevices.length - 1].deviceId)
            .then(() => {})
            .catch((e) => {
              alert(e);
            });
        }
      } else {
        console.log("No such device to toggle");
      }
    });
  };
  const toggleAudio = async (state) => {
    setmicToggle(!micToggle);
    rtc.localAudioTrack.setEnabled(state);
  };
  const newcapture = async () => {
    const player = document.getElementById("player1");
    navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: camToggle ? "face" : "environment" },
      })
      .then((stream) => {
        player.srcObject = stream;
        const imageWidth = document.getElementById("player1").offsetWidth;
        const imageHeight = document.getElementById("player1").offsetHeight;
        const outputCanvas = document.getElementById("output");
        const context = outputCanvas.getContext("2d");
        outputCanvas.width = imageWidth;
        outputCanvas.height = imageHeight;
        setTimeout(() => {
          context.drawImage(document.getElementById("player1"), 0, 0, 300, 400);
        }, 2000);
        setTimeout(() => {
          stopnewCapture(stream);
        }, 3000);
      })
      .catch((error) => {
        alert(error);
      });
  };
  const stopnewCapture = async (stream) => {
    stream.getTracks().forEach(function (track) {
      track.stop();
    });
  };

  const setOnlineBM = (val) => {
    setbmplay(val);
    var param = {
      type: 1,
      audit_id: audit_id,
      status: val,
    };
    socket.emit("pauseOnlineAudit", param, (data) => {
      if (data.socketEvent == `pauseOnlineAudit${audit_id}`) {
        setrmmplay(data.data.rmm_online);
        setbmplay(data.data.bm_online);
      }
    });
  };
  return (
    <NotifyScreen
      question={question}
      imgurl={imgurl}
      online={online}
      setonline={setonline}
      auditStart={auditStart}
      requestData={requestData}
      imagePreview={imagePreview}
      setrequestData={setrequestData}
      handleCaptureImage={handleCaptureImage}
      token={token}
      channel={channel}
      rating={rating}
      remark={remark}
      front={front}
      setFront={setFront}
      videoConstraints={videoConstraints}
      facingMode={facingMode}
      handleClick={handleClick}
      rtc={rtc}
      toggleCam={toggleCam}
      toggleAudio={toggleAudio}
      camToggle={camToggle}
      setcamToggle={setcamToggle}
      micToggle={micToggle}
      setmicToggle={setmicToggle}
      newcapture={newcapture}
      camToggleRef={camToggleRef}
      qty={qty}
      bmplay={bmplay}
      setbmplay={setbmplay}
      setOnlineBM={setOnlineBM}
    />
  );
};

export default NotifyView;
