import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import Sidebar from '../../Common/Sidebar/Sidebar';
import AgoraRTC, { IAgoraRTCClient } from "agora-rtc-sdk-ng"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './styles.css'

const NotifyScreen = (props) => {
  const { facingMode } = props

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const [menuShow, setMenushow] = useState(false);

  const handleSidebar = () => {
    setMenushow(!menuShow)
  }

  const splitImages = (image_taken) => {
    let splitImg = image_taken.split(',');
    return splitImg
  }
  return (
    <>
      <Header handleSidebar={handleSidebar} menuShow={menuShow} />
      {menuShow ?
        <Sidebar /> : null}
      <div>

        <section className="branch-area ">
          <div className="container ">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="branch-content main_page branch_ld_content mt-4">

                  <div className="branch-img-box p-3" >


                    <div id="player" style={{ zIndex: "1",position:"relative"}}>

                      <div style={{ position: "absolute", zIndex: 999, bottom: 0, right: 0 }}>
                        {
                          props.micToggle ?
                            <button className="toggle_btn" style={{marginRight:"0.5rem"}} onClick={()=>props.toggleAudio(false)}>
                              <i class="fas fa-microphone" style={{ color: "white" }}></i>
                            </button> :
                            <button className="toggle_btn" style={{marginRight:"0.5rem"}} onClick={()=>props.toggleAudio(true)}>
                              <i class="fas fa-microphone-slash" style={{ color: "white" }}></i>
                            </button>
                        }
                        {
                          props.camToggle ?
                            <button className="toggle_btn" onClick={() => {
                              props.camToggleRef.current=!props.camToggle
                              props.setcamToggle(!props.camToggle)
                              props.toggleCam(false)
                            }}>
                              <i class="fas fa-camera" style={{ color: "white" }}></i>
                            </button> :
                            <button className="toggle_btn" onClick={() => {
                              props.camToggleRef.current=!props.camToggle
                              props.setcamToggle(!props.camToggle)
                              props.toggleCam(true)
                            }}>
                              <i class="fas fa-camera" style={{ color: "white" }}></i>
                            </button>
                        }
                        {
                          props.bmplay ?
                            <button className="toggle_btn" style={{margin:"0.5rem"}} onClick={() => {
                              props.setOnlineBM(0)
                            }}>
                              <i class="fas fa-pause" style={{ color: "white" }}></i>
                            </button> :
                            <button  className="toggle_btn" style={{margin:"0.5rem"}} onClick={() => {
                              props.setOnlineBM(1)
                            }}>
                              <i class="fas fa-play" style={{ color: "white" }}></i>
                            </button>
                        }
                      </div>

                    </div>

                    <div style={{ position: 'absolute', zIndex: 0, top: '81px' }}>
                      {/* <button onClick={()=>props.newcapture()}>Capture</button> */}
                      <video id="player1" autoPlay style={{ position: "absolute", width: "300px",height:"400px", zIndex: '0' }} ></video>
                      <canvas id="output" style={{ width: "92%", zIndex: '1' }} ></canvas>
                    </div>

                  </div>
                  <div>
                    {/* <input type="button" >
                     <img src="/assets/images/voice-recording.png" alt="" className="branch--img" />
                    </input> */}
                    {/* <button href="#" className="brnc--call-btn">Auditor Will Start The Call</button> */}
                  </div>
                  {props.auditStart ?
                    <section>
                      <div>
                        <div>
                          <div>
                            <div>
                              <div className="container">
                                <p className="cll-img--hading">{props.question.audit_question}</p>

                                {props.question.image_capture == 1 && props.imagePreview ?
                                  
                                  <Carousel responsive={responsive} infinite={true} showDots={true}>
                                    {props.imagePreview.map((item, i) => {
                                      return (
                                        <div className="branch-img-box p-3">
                                          <img key={i} src={props.imgurl + item.image_data} className="branch--img" style={{ width: 200 }} />
                                        </div>
                                      )
                                    })
                                    }
                                  </Carousel>
                                  : null}

                              </div>
                              <div className="call--rating-box">
                                <form style={{paddingLeft:"1rem"}}>
                                {
                                    props.question.question_type == 2 ?
                                      <div className="rating-text" >
                                        <label className="form-title">Qty: <span>{props.qty}</span></label>
                                      </div> : null
                                  }
                                  {
                                    props.question.score_range == 1 ?
                                      <div className="rating-text">
                                        <label className="form-title">Rating: <span>{props.rating}</span></label>
                                      </div> : null
                                  }
                                  {/* <div className="form-group">
                                  <label className="form-title">Actionable</label>
                                  <select className="form-control select--action-bh">
                                    <option className>BH/RMM/AC</option>
                                    <option className>BH/RMM/AC</option>
                                    <option className>BH/RMM/AC</option>
                                  </select>
                                </div> */}
                                  {
                                    props.question.remark == 1 ?
                                      <div className="form-group">
                                        <label className="form-title">Remarks</label>
                                        <textarea className="form-control call--remarks" value={props.remark} rows={3} />
                                      </div>
                                      : null
                                  }
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section> :
                    <div className="main--titile-box">
                      <h4 className="call--title">Team ready for the call :</h4>
                      <div className="bnc-fom-listing-box">
                        <ul className="bnc-fom-list-menu">
                          <li className="bnc-fom-link"><span>Branch Manager Name(You)</span><small className="text-success">Joined</small> </li>
                          <li className="bnc-fom-link"><span>RMM</span><small><a className="cl-ofline--btn" href="#">{props.online ? "Online" : "Offline"}</a> </small> </li>
                          {/* <li className="bnc-fom-link"><span>RMM</span><small><a className="cl-ofline--btn" href="#">{props.online?"Online":"Offline"}</a> <a href className="notif-btn">Notify</a> </small> </li> */}
                          {/* <li className="bnc-fom-link"><span>Admin Name</span > <small className="text-success">Joined</small> </li> /} */}
                        </ul>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* {props.imagePreview &&
          <section>
            <div className="container ">
            <Carousel>
                {props.imagePreview.map((item, i) => {
                  return (
                    <div className="branch-img-box p-3">
                      <img key={i} src={props.imgurl + item.image_data} className="branch--img" style={{ width: 200 }} />
                    </div>
                  )
                })
                }
              </Carousel>
            </div>


          </section>} */}
        {/* <button onClick={() => captuepic()}>click picture</button> */}
        {/* <canvas id="output" style={{ position: "absolute", width: "590px" }} ></canvas> */}
      </div>
    </>
  );
}

export default NotifyScreen;